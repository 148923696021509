import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PhotoUploadCardComponent } from './photo-upload-card.component';

@NgModule({
  imports: [CommonModule, FormsModule, MatIconModule, MatProgressSpinnerModule],
  exports: [PhotoUploadCardComponent],
  declarations: [PhotoUploadCardComponent],
  providers: [],
})
export class PhotoUploadCardModule {}
