import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileCardComponent } from './file-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ImageLoaderModule } from '../image-loader/image-loader.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [FileCardComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    ImageLoaderModule,
    MatButtonModule,
  ],
  exports: [FileCardComponent],
})
export class FileCardModule {}
