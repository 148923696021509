import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BarcodeScannerComponent } from './barcode-scanner.component';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';

@Component({
  selector: 'fc-add-device-input[title][serialLength]',
  template: `
    <mat-form-field appearance="outline">
      <mat-label>{{ title }}</mat-label>
      <input
        #matAutocompleteTrigger="matAutocompleteTrigger"
        type="text"
        matInput
        [required]="required"
        [maxlength]="serialLength"
        [formControl]="itemsControl"
        [matAutocomplete]="auto"
        [autofocus]="false"
        (input)="onInput($event)"
      />
      <mat-spinner
        matSuffix
        *ngIf="loading"
        [diameter]="20"
        [strokeWidth]="4"
      ></mat-spinner>
      <fc-icon-button
        *ngIf="!loading"
        class="barcode-scanner-button"
        matSuffix
        (click)="openBarcodeScanner($event)"
      >
        <fc-icon icon="icon-barcode_scanner" />
      </fc-icon-button>
      <mat-autocomplete
        (optionSelected)="onOptionSelected($event)"
        #auto="matAutocomplete"
        [displayWith]="displayFn"
      >
        <mat-option *ngFor="let option of itemList$ | async" [value]="option">
          {{ option.serial }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="itemsControl.hasError('required')"
        >This field shouldn't be empty</mat-error
      >
      <mat-error *ngIf="itemsControl.hasError('notFound')"
        >Serial number not found</mat-error
      >
      <mat-error *ngIf="itemsControl.hasError('serverError')">{{
        itemsControl.getError('serverError')
      }}</mat-error>
    </mat-form-field>
  `,
  styles: [
    `
      @use 'var' as *;

      :host {
        .barcode-scanner-button {
          display: none;
          @media (max-width: $sm-size) {
            display: inline-flex;
          }
        }
      }
    `,
  ],
})
export class AddDeviceInputComponent<T extends { serial: string }>
  implements AfterViewInit
{
  @ViewChild('matAutocompleteTrigger', { static: true })
  matAutocomplete: MatAutocompleteTrigger;
  @Input() title: string;
  @Input() requestFunction: Function;
  @Input() verifiedFunction: Function;
  @Input() required = true;
  @Input() serialLength: number;
  @Output() deviceSelected = new EventEmitter<T>();
  itemsControl: FormControl<string | T> = new FormControl<string | T>('');
  loading: boolean;
  itemList$: Observable<T[]>;

  constructor(private overlay: Overlay) {}

  ngAfterViewInit() {
    this.setValidators();
    this.getList('');
  }

  onInput(evt: Event): void {
    const { value } = evt.target as HTMLInputElement;
    if (value.length > this.serialLength) {
      // show error if length is more than serial
      return;
    }
    if (value.length === this.serialLength) {
      this.verifySerial(value);
    } else {
      this.getList(value);
    }
  }

  displayFn(value: T): string {
    return value && value.serial ? value.serial : '';
  }

  getList(serial: string): Observable<T[]> {
    this.loading = true;
    this.itemList$ = this.requestFunction(serial).pipe(
      finalize(() => (this.loading = false)),
    );
    return this.itemList$;
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent): void {
    this.verifySerial($event.option?.value?.serial);
  }

  verifySerial(serial: string): void {
    return this.verifiedFunction(serial.toUpperCase())
      .pipe(
        take(1),
        map((item: T) => {
          if (item) {
            this.itemsControl.setValue(item);
            this.deviceSelected.emit(item);
            this.matAutocomplete?.closePanel();
          }
        }),
        catchError((err) => {
          this.itemsControl.markAsTouched();
          if (err.status !== 404) {
            this.itemsControl.setErrors({ serverError: err.error[0] });
          } else {
            this.itemsControl.setErrors({ notFound: true });
          }
          return of(err);
        }),
      )
      .subscribe();
  }

  openBarcodeScanner(evt): void {
    evt.stopPropagation();
    evt.preventDefault();
    const overlayRef = this.overlay.create({
      width: '100%',
      height: '100%',
    });
    const userProfilePortal = new ComponentPortal(BarcodeScannerComponent);
    const componentRef = overlayRef.attach(userProfilePortal);
    combineLatest([
      componentRef.instance.code$.asObservable(),
      componentRef.instance.close$.asObservable(),
    ]).subscribe(([serial, close]) => {
      if (close) {
        overlayRef.detach();
        return overlayRef.dispose();
      }
      if (serial) {
        this.verifySerial(serial);
        overlayRef.detach();
        overlayRef.dispose();
      }
    });
  }

  private setValidators(): void {
    this.itemsControl.addValidators(Validators.maxLength(this.serialLength));
    this.itemsControl.addValidators(Validators.minLength(this.serialLength));
    if (this.required) this.itemsControl.addValidators(Validators.required);
  }
}
